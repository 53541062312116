<template>
  <div>
    <router-view />
    <!-- Layers where we teleport modals, sidebar, ... to -->
    <div id="layer1"></div>
    <div id="layer2"></div>
    <div id="layer3"></div>
    <div id="layer4"></div>
    <div id="layer5"></div>
    <RMessage />
    <RIdle />
    <RBrowserTabs />
    <RRefreshToken />
  </div>
</template>

<script>
import markerSDK from "@marker.io/browser"

export default {
  name: "App",
  methods: {
    async initMarker() {
      if (process.env.VUE_APP_MARKER_IO_PROJECT_ID) {
        await markerSDK.loadWidget({
          project: process.env.VUE_APP_MARKER_IO_PROJECT_ID,
        })
      }
    },
  },
  async mounted() {
    await this.initMarker()
  },
}
</script>
